import * as React from "react"
import { PageProps } from "gatsby"

import Layout from "@components/Layout"
import SEO from "@components/SEO"

type Props = PageProps<null>

function NotFoundPage({ location }: Props) {
  return (
    <Layout
      location={location}
      mainClassName="min-h-mobile-main lg:min-h-desktop-main"
    >
      <SEO title="404: Not Found" slug="/404-not-found/" />
      <div className="h-full w-full flex flex-col items-center justify-center">
        <h1>404: Not Found</h1>
        <p>The page you are looking for cannot be found</p>
      </div>
    </Layout>
  )
}

export default NotFoundPage
